.adminButton {
    width: fit-content;
    min-width: 20%;
    display: flex;
    justify-content: space-between;
    border-radius: 5px;
    padding: 1rem 1.5rem;
    background: #88B28C;
    cursor: pointer;

    &:hover {
        transition: 0.3s all;
        background: #78c980;
        color: antiquewhite;
        // transform: scale(1.01);
    }

    &:focus {
        transition: 0.3s all;
        transform: scale(0.96);
    }
}

//___________________ MEDIA CONTROLS ____________________//


@media (max-width: 445px) {
    .adminButton {
        margin-right: -25px;
    }
}