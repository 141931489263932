.admin__content__zone {
    width: 90%;
    max-width: 1350px;
    // position: relative;
    min-height: fit-content;
    border-radius: 5px;
    padding: 50px 0px;
    background: #FFF;
    box-shadow: rgba(0, 0, 0, 0.25) 5px 5px 35px;
    margin: 4rem auto;

    & h2 {
        // margin: 0 5rem;
        font-size: 1.5rem;
        font-weight: bold;
        color: #1A2578;
        letter-spacing: 1px;
    }

    & .redactor__page__title {
        display: flex;
        margin: 0 5rem;
        justify-content: space-between;
    }
}

.buttons__under__forms {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    margin: 0 4rem;
    letter-spacing: 1px;
    font-size: 12px;
}