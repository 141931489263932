.form {
  &__number {
    width: 70%;
    margin: 50px auto;
  }

  &__numberWrapp table {
    width: 100%;
  }

  &__numberInput {
    width: 50px;
    height: 25px;
    border: 1px solid #bddff8;
    padding: 0 18px;

    &:focus {
      transition: 0.3s all;
      border: 1px solid #3597dd;
    }

    &:hover {
      transition: 0.3s all;
      border: 1px solid #3597dd;
    }
  }
}

@media (max-width: 800px) {
  .form {
    &__number {
      width: 85%;
    }
  }
}

@media (max-width: 600px) {
  .form {
    &__number {
      width: 100%;
    }
  }

  .adaptive-table table {
    display: block;
  }

  .adaptive-table td {
    display: block;
  }
}
