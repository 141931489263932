
.fon__dark {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.123);
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalWindow {
    height: fit-content;
    width: fit-content;
    min-width: 40%;
    min-height: 100px;
    padding: 2rem;
    background-color: white;
    border-radius: 5px;
}

// .btn__delete {
//     border-radius: 5px;
//     padding: 1rem 1.5rem;
//     min-width: 20%;
//     height: 40px;
//     width: fit-content;
//     background: #e2e2e2;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     font-size: .8rem;
//     cursor: pointer;
// }

// .btn__delete:hover {
//     background-color: #fa8a8a;
//     color: white;
// }

// .alternative__reader {
//     display: flex;
//     justify-content: space-between;
//     gap: 1rem;
// }