.admin__table {
    width: 100%;
    margin: 4rem 0;
    // width: calc(100% - 8rem);

    &__tr {
        display: grid;
        grid-template-columns: 1fr 5fr 1fr 1fr 2fr;
        padding: 0 5rem;
        
    }
    
    &__th {
        padding: 1.25rem 1rem;
        background-color: #BDDFF8;
        color: white;
        font-weight: bold;
    }

    &__td {        
        padding: 1.25rem 1rem;

    }
    &__tr:nth-child(odd) {
        background-color: #eee;
    }
    &__tr:first-child {
        background-color: #BDDFF8;
    }
}