
.btn__delete {
    border-radius: 5px;
    padding: 1rem 1.5rem;
    min-width: 20%;
    height: 40px;
    width: fit-content;
    background: #e2e2e2;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: .8rem;
    cursor: pointer;
}

.btn__delete:hover {
    background-color: #fa8a8a;
    color: white;
}

.alternative__reader {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
}

.btn__deletepoint {
    color: white;
    background-color: #fa8a8a;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: absolute;
    top: -10px;
    right: -10px;

    &__container {position: relative;}
}