.startPage {
  min-height: 95vh;
  // background: antiquewhite;
  background: #f2f1f1;
  // background: url(https://catherineasquithgallery.com/uploads/posts/2021-03/1614846215_15-p-fon-flag-rossii-16.jpg) center / cover no-repeat;
  // background: url(https://catherineasquithgallery.com/uploads/posts/2021-03/1614846083_61-p-fon-trikolor-77.jpg) center / cover no-repeat;
  // background: url(https://celes.club/uploads/posts/2022-06/1654601831_45-celes-club-p-patrioticheskie-oboi-krasivie-49.png) center / cover no-repeat;
  // background: url(../../img/bgStartPage1.jpeg) center / cover no-repeat;
  // background: url(../../img/bgStartPageLowQuality.jpeg) center / cover no-repeat;
  // background: url(../../img/bgStartPageMediumQuality.jpeg) center / cover no-repeat;
  // background: url(https://drasler.ru/wp-content/uploads/2019/06/Флаг-с-гербом-России-обои017.jpg) center / cover no-repeat;
  // background: linear-gradient(to top, #00416a, #e4e5e6);
  background: linear-gradient(to top, rgb(68 140 185 / 75%), #f0f7ff);
  position: relative;

  &__title {
    padding-top: 25px;
    padding-bottom: 25px;
    font-size: 36px;
    text-align: center;
    border-bottom: 1px solid #ccc;
    // text-shadow: 1px 2px 3px rgb(112, 188, 255);
    // letter-spacing: 2px;
    // font-family: 'Oswald', sans-serif;
    // color: rgba(0, 0, 0, 0.445)
    color: black;
  }

  &__adminButtonWrapp {
    position: absolute;
    right: 20px;
    top: 105px;
  }

  &__wrappToBeginCopy {
    display: flex;
  }

  &__connectionForm {
    // position: absolute;
    background-color: brown;
  }


  &__wrapp {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 50px;
  }

  &__hr {
    height: 5px;
    width: 50px;
    // background-color: #bddff8;
    background-color: whitesmoke;
    align-self: self-start;
    margin-bottom: 10px;
    position: relative;
    left: -5px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    // align-items: end;

    margin-top: 50px;
    font-size: 22px;
    // width: 50%;
    width: 70%;
  }

  &__innerItem {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;

    font-size: 22px;
    // width: 50%;
  }

  &__name {
    width: 60%;
    margin: 0 25px;
    margin-left: 50px;
  }


  &__button {
    max-width: 250px;
    min-width: 100px;
    min-height: 50px;
    padding: 5px 10px;
    background: #88b28c;
    margin: 0 30px;
    border-bottom: 1px solid;
    border-radius: 5px;
    position: relative;
    top: 15px;
    margin-bottom: 25px;

    &:hover {
      transition: 0.3s all;
      background: #78c980;
      color: antiquewhite;
      // transform: scale(1.01);
    }

    &:focus {
      transition: 0.3s all;
      transform: scale(0.99);
    }
  }

  &__button__download {
    min-width: 50px;
    height: 50px;
    background: #9ac8eb;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #134e7c;
    border-radius: 5px;
    position: relative;
    top: 15px;
    color: #134e7c;
    margin-left: 10px;
    z-index: 15;
    transition: 1s all;

    &:hover {
      transition: 0.3s all;
      background: #bddff8;
      color: #5b96c4;
    }

    &:focus {
      transition: 0.3s all;
      transform: scale(0.96);
    }
  }

  &__button__inputWrapp {
    min-width: 335px;
    height: 50px;
    padding: 2px 0 2px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // border: 3px solid #589acd;
    // border: 3px solid #76b1dd;
    border-top: 3px solid #589acd;
    border-left: 3px solid #589acd;
    border-bottom: 3px solid #589acd;
    border-radius: 5px;
    position: relative;
    top: 15px;
    color: #134e7c;
    margin-left: 1rem;
    z-index: 15;
    transition: 1s all;



    &:focus {
      transition: 0.3s all;
      transform: scale(0.96);
      color: #5b96c4;
    }
  }

  &__button__inputDownload {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    position: relative;
    background: #76b1dd;
    color: #134e7c;
    margin-left: 1rem;
    z-index: 15;
    transition: 1s all;

    border: 3px solid #589acd;

    &:hover {
      transition: 0.3s all;
      background: #bddff8;
      color: #5b96c4;
    }

    &:focus {
      transition: 0.3s all;
      transform: scale(0.96);
    }
  }

  &__createAltP {
    position: relative;
    min-width: 50px;
    height: 50px;
    margin-left: 15px;
    top: 15px;
    background: aliceblue;
  }
}

.actionLink {
  background: #bddff8;
  // color: #78c980;
  // color: #76ff84;
  color: rgb(67, 170, 72);
  // border-bottom: 1px solid #9ac8eb;
  border: 9px solid #78c980;
}

.btns__forms {
  display: flex;
  // flex-direction: column;
}


//___________________ MEDIA CONTROLS ____________________//


@media(max-width: 1340px) {
  .startPage {
    &__item {
      width: 85%;
    }
  }
}

@media (max-width: 1200px) {
  .startPage {
    &__wrapp {
      align-items: unset;
      padding: 0 50px 75px;
    }

    &__button__inputWrapp {
      min-width: unset;
      max-width: 335px;
    }

    &__item {
      width: 100%;
    }
  }
}

@media (max-width: 1075px) {
  .startPage {
    &__wrapp {
      padding: 0 50px 75px;
    }
  }
}


@media (max-width: 1073px) {
  .startPage {
    &__hr {
      margin-bottom: 0;
    }

    &__wrapp {
      margin-top: 30px;
    }

    &__wrappToBeginCopy {
      display: flex;
      align-items: center;
    }

    &__adminButtonWrapp {
      right: 50px;
    }

    &__button {
      width: 100%;
      margin-bottom: unset;
      margin-left: 0;
    }

    &__button__download {
      margin: 0px auto;
    }

    &__item {
      padding: 30px;
      border: 1px solid aliceblue;
      border-radius: 10px;
      width: 100%;
    }

    &__button__inputWrapp {
      margin-top: 20px;
      margin-left: 0;
    }

    &__shortDescr {
      border-bottom: 1px solid aliceblue;
      padding-bottom: 10px;
    }

  }

  .btns__forms {
    flex-direction: column;

  }
}

@media(max-width: 850px) {
  .startPage {
    &__button__date {
      width: 30px;
    }
  }
}

@media (max-width: 768px) {
  .startPage {
    &__name {
      width: unset;
    }

    // &__button__date {
    //   width: 30px;
    // }
    &__button__inputWrapp {
      // width: 40px;
      padding: 0 0 0 10px;
    }

    &__button__inputDownload {
      padding: 0 10px;
    }

    &__shortDescr {
      margin: 15px 0;
      width: unset;
      font-size: 24px;
    }

    &__item {
      padding: 30px 30px 50px 30px;
    }

    &__innerItem {
      flex-direction: column;
      align-items: center;
    }

    &__button__date {
      width: unset;
    }

    &__wrappToBeginCopy {
      justify-content: center;
    }

    &__button__download {
      margin: unset;
    }
  }
}

@media (max-width: 600px) {
  .startPage {
    &__innerItem {
      align-items: center;
    }

    &__name {
      width: 100%;
      margin-bottom: 10px;
    }

    &__button {
      width: 100%;
    }

    &__button__download {
      min-width: 50px;
    }
  }

}

@media (max-width: 445px) {
  .startPage {
    &__wrapp {
      padding: 0 25px 75px 25px;
    }

    &__item {
      padding: 30px 30px 35px 30px;
    }

    &__wrappToBeginCopy {
      width: 90%;
      margin: 0 auto;
    }

    &__button__inputWrapp {
      width: 90%;
      margin: 20px auto;
    }

    &__button__date {
      width: 30px;
    }

    &__button__inputDownload {
      width: 235px;
      margin-left: 10px;
    }
  }

}