.QuestionFields {
    

}

.questionFields__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
}

.btn__adQduestion {
    height: 40px;
    width: 40px;
    background: #88b28c;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 25px;
    color: white;
    cursor: pointer;
}

.chBoxAndText {
    & > div:last-child textarea:first-child {
        // border: 5px solid red;
        background-color: #eee;
    }
}