.header {
    top: 0;
    width: 100%;
    min-height: 10vh;
    // background: #BDDFF8;
    font-size: 46px;
    text-align: center;
    line-height: 86px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    z-index: 20;
    // background: linear-gradient(177deg, rgb(178 191 195), transparent);
    // background: linear-gradient(177deg, rgb(178, 191, 195), rgb(244 244 244));
    // color: #395c50;
    color: #fff;
    // background: linear-gradient(to right, #e1fedc, #f3d2d8);
    // background: linear-gradient(to bottom, #40a5db 0%, #005c9d 100%);
    background: linear-gradient(-10deg, #40a5db 0%, #005c9d 100%);

    // :nth-child(2) {
    //     font-size: 22px;
    // }

    &__title {
        position: relative;
        font-size: 32px;
        cursor: pointer;
        max-width: 70%;
        line-height: 3rem;
        text-decoration: none;
        color: #fff;

        &:hover {
            color: #fff;
        }
    }

    &__emblem {
        position: absolute;
        background: url("../../img/emblem.png") center center no-repeat;
        background-size: contain;
        left: 0;
        width: 10vh;
        height: 10vh;
    }
}

.btn_auth {
    font-size: 20px;
    position: absolute;
    bottom: -15px;
    right: 20px;
    opacity: 0.7;
    cursor: pointer;
}


//___________________ MEDIA CONTROLS ____________________//

@media (max-width: 1305px) {
    .header {


        &__title {
            font-size: 28px;
        }
    }
}

@media (max-width: 1000px) {
    .header {
        position: relative;
    }
}

@media (max-width: 768px) {
    .header {


        &__title {
            font-size: 26px;
            margin: 0 85px;
        }
    }
}

// @media (max-width: 535px) {
//     .btn_auth {
//         top: 25px;
//     }
// }

@media (max-width: 442px) {
    .header {
        &__title {
            font-size: 22px;
        }
    }
}

@media (max-width: 400px) {
    .header {
        &__title {
            font-size: 18px;
            line-height: 35px;
        }
    }

    .btn_auth {
        font-size: 16px;
    }
}