.dualRingSpinnerWrapp {
    position: absolute;
    margin-top: 10vh;
    width: 100vw;
    height: 85vh;
    z-index: 999;
    display: flex;
    align-items: center;
    background: #f2f1f1;
    overflow: hidden;
    padding-bottom: 10vh;
}

.dualRingSpinner {
    display: inline-block;
    width: 64px;
    height: 64px;
}

.dualRingSpinner:after {
    content: " ";
    display: block;
    width: 100px;
    height: 100px;
    margin: 1px;
    border-radius: 50%;
    border: 10px solid #09f;
    border-color: #09f transparent #09f transparent;
    animation: dual-ring-spin 1s linear infinite;
}

@keyframes dual-ring-spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}