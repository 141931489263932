.formField {
    margin: 4rem;

    &__oneRow {
        display: grid;
        grid-template-columns: 2fr 3fr;
        gap: 1rem;
        padding: 2rem 0;

        & div {
            display: flex;
            align-items: center;
        }
    }

    &__twoSelect {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
        padding: 3rem 0;

    }

    &__buttons {
        display: flex;
        justify-content: flex-end;
        font-size: 12px;
        letter-spacing: 1px;
    }
}

.formField input,
.formField select {
    border: 1px solid #eee;
    background-color: #fdfdfd;
    border-radius: 5px;
    padding: 1rem;
    width: 100%;
}

.formField option {
    display: inline-block;
    padding: 1rem;
}

.copyLink {
    position: absolute;
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    align-items: center;
    height: fit-content;
    width: fit-content;
    padding: .5rem;
    background-color: #eee;
    border: 1px solid rgb(201, 201, 201);
    border-radius: 5px;
    z-index: 15;

    & input {
        background-color: white;
        padding: .5rem;
        min-width: 250px;
    }
}