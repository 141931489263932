.formField {
    margin: 5rem;
    &__oneField {
        padding: 2rem 0;
    }
    & textarea {
        border: 1px solid #eee;
        background-color: #fdfdfd;
        border-radius: 5px;
        padding: 1rem;
        height: fit-content;
        width: 100%;
        min-height: 6rem;
    }
}

.fieldName {
    font-size: 1.25rem;
    font-weight: bold;
}

.q__field__margin {
    margin-top: 2rem;
}

.btn_new_q_on_q  {
    background-color: #e2e2e2;
    margin-right: 2rem;
}


.toggle {
    position : relative ;
    display : inline-block;
    width : 60px;
    height : 32px;
    background-color: #e2e2e2;
    border-radius: 30px;
    border: 2px solid rgb(110, 110, 110)2e2;
}
       
/* After slide changes */
.toggle:after {
    content: '';
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #88b28c;
    top: 1px; 
    left: 1px;
    transition:  all 0.2s
}       
/* Checkbox checked effect */
.checkbox:checked + .toggle::after {
    left : 29px; 
}
       /* Checkbox checked toggle label bg color */
.checkbox:checked + .toggle {
    background-color: rgb(177, 218, 177);
}
/* Checkbox vanished */
.checkbox { 
    display : none;
}