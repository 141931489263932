.QuestionFields {
    

}

.questionFields__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
}

.btn {
    &__adQduestion {
        height: 40px;
        width: 40px;
        background: #88b28c;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        font-size: 25px;
        color: white;
        cursor: pointer;
    }

    &__add_alt {
        font-weight: normal;
        background: #88b28c;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: .83rem;
        cursor: pointer;
    }
}
.fieldName {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
// .q__field__margin{
//     &:last-child {border: 5px solid red;}
// }

