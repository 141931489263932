.formWrappLogin {
    width: 40%;
    position: relative;
    min-height: fit-content;
    border-radius: 25px;
    padding: 0px 50px 70px;
    background: #FFF;
    box-shadow: rgba(0, 0, 0, 0.25) 5px 5px 35px;
    margin: 4rem auto;
    min-height: 380px;
}

.loginForm {
    margin: 0rem;
    display: flex;
    flex-direction: column;


    & input {
        margin: 2rem;
        height: 3rem;
        padding: 0 1rem;
        border: 1px solid #eee;
        border-radius: 5px;
        background: #eee;
    }
}

.container {
    min-height: 85vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 1500px) {
    .loginForm {
        margin: 0rem;
    }

    .loginForm input {
        margin: 1rem 0;
    }

    .loginForm button {
        position: static;
        width: 100%;
    }
}

@media (max-width: 1000px) {
    .formWrappLogin {
        width: 70%;
    }
}

@media (max-width: 550px) {
    .formWrappLogin {
        width: 80%;
    }
}