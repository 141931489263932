.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 18px;
    text-align: center;
    line-height: 50px;
    padding: 0 25px;
    position: sticky;
    z-index: 20;
    bottom: 0;

    // :nth-child(2) {
    //     margin: 0 13px;
    // }

    // color: #395c50;
    color: #fff;
    // background: linear-gradient(to right, #3f5efb96, #fc466b70);
    // background: linear-gradient(to right, #e1fedc, #f3d2d8);
    // background: linear-gradient(to bottom, #40a5db 0%, #005c9d 100%);
    // background: linear-gradient(to bottom, #4688ac 0%, #005c9d 100%);
    background: linear-gradient(-10deg, #40a5db 0%, #005c9d 100%);

    // p {
    //     font-size: 18px;
    //     // user-select: none;
    // }

    &__copyright {
        order: 0;
    }

    &__linkMap {
        text-decoration: none;
        color: white;

        &:hover {
            color: #fff;
        }
    }
}

//___________________ MEDIA CONTROLS ____________________//

@media (max-width: 1305px) {
    .footer {
        // position: unset;
        line-height: 35px;
        font-size: 14px;

        :nth-child(1) {
            padding-right: 13px;
            // border-right: 1px solid;
        }

        :nth-child(2) {
            padding: 13px;
            border-right: 1px solid;
            border-left: 1px solid;
            font-size: 18px;
        }

        :nth-child(3) {
            padding-left: 13px;
            // border-left: 1px solid;
        }
    }
}

@media (max-width: 1000px) {
    .footer {
        position: unset;
    }
}

@media (max-width: 600px) {
    .footer {
        line-height: 30px;
    }
}

@media (max-width: 400px) {
    .footer {
        padding: 0 15px;
    }
}

@media (max-width: 647px) {
    .footer {
        padding: 0 15px;
        flex-direction: column;

        :nth-child(1) {
            padding: 10px;
        }

        &__dept {
            border-bottom: 1px solid;
            border-right: unset;
            border-left: unset;
        }

        &__copyright {
            order: +2;
        }

        &__linkMap {
            padding: 10px;
            border-bottom: 1px solid;
            border-right: unset;
            border-left: unset;
        }
    }
}