.markButton {
    height: 20px;
    width: 20px;
    background-position: center;
    background-size: cover;
    display: inline-block;
    margin: 0 1rem;
    cursor: pointer;
}

.completeButton {
    background-image: url(./imgs/yes.png);
}
.noCompleteButton {
    background-image: url(./imgs/no.jpg);
}
.editeButton {
    background-image: url(./imgs/edit.png);
}
.deleteButton {
    background-image: url(./imgs/delete.png);
}