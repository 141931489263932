.button {
    position: absolute;
    width: 200px;
    height: 50px;
    border-radius: 5px;

    &:hover {
        transition: 0.3s all;
        background: #78c980;
        color: antiquewhite;
        // transform: scale(1.01);
    }

    &:focus {
        transition: 0.3s all;
        transform: scale(0.96);
    }
}


.buttonForward {
    bottom: 30px;
    right: 125px;
    background: #88B28C;
}

//___________________ MEDIA CONTROLS ____________________//


@media (max-width: 1500px) {
    .buttonForward {
        right: 30px;
    }
}

@media (max-width: 1300px) {
    .buttonForward {
        right: 35px;
    }
}

@media (max-width: 768px) {
    .buttonForward {
        right: 50px;
    }
}

@media (max-width: 470px) {
    .buttonForward {
        right: 50%;
        transform: translateX(50%);
    }

    .loginForm button {
        transform: unset;
    }
}