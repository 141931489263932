.page404 {
    height: 100vh;
    background: #eee;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // background: url("../../img/page404Img.webp") center no-repeat;
    text-align: center;

    &__buttonBack {
        width: 185px;
        height: 30px;
        position: absolute;
        bottom: 33%;
        left: 47%;
        opacity: 0;
    }

    &__content {
        height: 85vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 7%;
    }

    &__title {
        font-size: 270px;
        color: #005c9d
    }

    h4 {
        font-size: 32px;
        color: #005c9d;
    }


}