.form {
    min-height: 85vh;
    padding: 0px 50px 75px;
    position: relative;
    background: #f2f1f1;
    background: linear-gradient(to top, #2b729e, #e4e5e6);
    // background: url(../../img/bgForm.jpg) center / cover no-repeat;
    // background: url(https://celes.club/uploads/posts/2022-06/1654601831_45-celes-club-p-patrioticheskie-oboi-krasivie-49.png) center / cover no-repeat;
    // background: url(https://user.vse42.ru/files/P_S1920x1279q80/Wnormal700_WSg12x10r0z172/ui-5c7cb8a7798149.02700648.jpeg) center / cover no-repeat;
    // background: url(https://cbgd.ask.fm/e71/c978a/15cf/4e0e/a4ea/36aebe079264/original/691295.jpg) center / cover no-repeat;
    // background: url(https://catherineasquithgallery.com/uploads/posts/2021-02/1612800966_98-p-nezhno-goluboi-fon-s-oblakami-113.jpg) center / cover no-repeat;
    // background: url(https://fotostate.ru/wp-content/uploads/2023/03/12/1678640719_12_ygop.jpg) center / cover no-repeat;
    // background: url(https://catherineasquithgallery.com/uploads/posts/2021-03/1614852584_78-p-foni-dlya-prezentatsii-besplatno-80.png) center / cover no-repeat;
    background: url(../../img/bgForm1.jpg) center / cover no-repeat;

    &__title {
        max-width: 1024px;
        font-size: 26px;
        margin: 0 auto;
        padding: 50px 0;
        text-align: center;
    }

    &__question {
        font-size: 26px;
        padding-top: 115px;
        padding-bottom: 10px;
        border-bottom: 1px solid #ddd;
    }

    &__count {
        font-size: 24px;
        position: absolute;
        top: 30px;
        right: 50px;
        color: #878787;
    }

    &__noLocation {
        font-size: 26px;
        text-align: center;
        padding-top: 50%;
        transform: translateY(-50%);
    }
}

.formWrapp {
    width: 70%;
    position: relative;
    min-height: 100%;
    border-radius: 25px;
    padding: 0px 125px 150px;
    background: #FFF;
    box-shadow: rgba(0, 0, 0, 0.25) 5px 5px 35px;
    margin: 0 auto;
}

.form__answer.selectedRadio {
    border: 1px solid #3597dd;

}


//___________________ MEDIA CONTROLS ____________________//


@media (max-width: 1534px) {
    .formWrapp {
        padding: 0px 35px 130px;
    }
}

@media (max-width: 1300px) {
    .form {
        padding: 0 50px 75px 50px;
    }

    .formWrapp {
        width: 90%;
    }

}

@media(max-width: 1100px) {
    .formWrapp {
        width: 100%;
    }
}

@media (max-width: 768px) {
    .formWrapp {
        width: 100%;
        padding: 0px 50px 120px;
    }
}

@media (max-width: 675px) {
    .form {
        &__wrappAnswer {
            grid-template-columns: unset;
        }

        &__question {
            font-size: 22px;
            font-weight: 600;
        }
    }
}

@media (max-width: 470px) {
    .form {
        padding: 0 25px 75px 25px;
    }

    .formWrapp {
        padding: 0px 20px 110px;
    }
}