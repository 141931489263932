/* AdaptiveTable.css */
.adaptiveTable {
    margin-top: 50px;
    overflow-x: auto;

    &__check {
        position: relative;
        width: 100%;
        height: 30px;
        cursor: pointer;
        line-height: 30px;
        padding: 0 10px;
        // z-index: -1;

        &:hover {
            border: 1px solid #3597dd;
            border-radius: 3px;
        }

        &:focus {
            border: 1px solid #3597dd;
            border-radius: 3px;
        }
    }


    &__wrappCheck {
        cursor: pointer;
        min-width: 50px;
    }

    &__wrappTd {
        position: relative;
        z-index: 1;
        cursor: pointer;
    }
}

.textAnswer {
    min-height: 60px;
    border: 1px solid #9E9E9E;
    border-radius: 3px;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th,
td {
    border: 1px solid #ddd;
    text-align: center;
    padding: 8px;
}

th {
    background-color: #f2f2f2;
}

/* Стили для мобильных устройств */
// @media (max-width: 600px) {

//     th,
//     td {
//         padding: 4px;
//         font-size: 14px;
//     }
// }



@media (max-width: 951px) {

    .adaptiveTable {
        &__check {
            width: 50px;
            border: 1px solid #b0b0b0;
            border-radius: 3px;
        }

        &__vQuestion {
            font-size: 18px;
        }

        &__wrappCheck {
            padding: 8px 20px;
        }
    }

    thead {
        display: none;
    }

    tr {
        margin-bottom: 25px;
        display: block;
        border-bottom: 2px solid #ddd;
    }

    td {
        display: block;
        text-align: right;
        font-size: 16px;
        font-weight: bold;
        border-bottom: 1px dotted #ccc;
    }

    td:last-child {
        border-bottom: 0;
    }

    td:before {
        content: attr(data-label);
        float: left;
        // font-weight: bold;
        font-weight: 400;
        line-height: 30px;
        // text-transform: uppercase;
    }

}