.form {
    &__yourAnswer {
        width: 100%;
        resize: none;
        height: 100px;
        padding: 5px 10px;
        border: 1px solid #BDDFF8;
        border-radius: 3px;
        // margin-left: 25px;

        &:hover {
            transition: 0.3s all;
            border: 1px solid #3597dd;
        }

        &:focus {
            border: 1px solid #3597dd;
        }
    }
}

.form__answer.selectedCheckbox {
    border: 1px solid #3597dd;
    /* Измените стиль границы на желаемый */
}