.form {
    &__freeAnswerWrapp {
        width: 60%;
        height: 250px;
        border-radius: 10px;
        margin: 25px auto;
        padding: 15px;
        border: 1px solid #BDDFF8;
        background: linear-gradient(0deg, #EAEAEA 0%, #EAEAEA 100%);

        &:hover {
            transition: 0.3s all;
            border: 1px solid #3597dd;
        }

    }

    &__freeAnswer {
        width: 100%;
        height: 100%;
        resize: none;
    }
}