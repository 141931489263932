.form {
    &__wrappAnswer {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        row-gap: 30px;
        column-gap: 20px;
        margin-top: 40px;
        // padding: 0 50px;

    }

    &__answer {
        display: flex;
        align-items: center;
        word-wrap: break-word;
        column-gap: 10px;
        border: 1px solid #BDDFF8;
        border-radius: 3px;
        padding: 5px;
        transition: 0.3s all;
        cursor: pointer;
        z-index: 10;
    }

    &__check {
        min-width: 75px;
        height: 30px;
        cursor: pointer;
        z-index: -1;
    }

    &__answerDescr {
        max-width: 350px;
    }

    &__wrappContent {
        display: flex;
        align-items: center;
    }
}